.titleContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.titleText {
    position: relative;
    font-size: 2.5em;
    font-weight: 800;
    line-height: 0.85em;
    text-transform: uppercase;
    padding: 0 0.1em;
    margin: 0.2em 0;
}

@media(min-width: 1000px) {
    .titleText {
        font-size: 3.5em;
    }
}
