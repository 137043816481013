.outlineText, .whiteOutlineText {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4A4A4A;
}

.whiteOutlineText {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
}

.sectionContainer {
    text-align: center;
    width: 100%;
    padding: 4em 0.5em;
}

.whiteShadow {
    text-shadow: 2px 2px white;
}

.greenUnderline:before,
.underline:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    border-bottom: 7px solid #84F3DD;
    z-index: -1;
}

.underline.green:before {
    border-bottom: 7px solid #7ED32185;
}

.underline.pink:before {
    border-bottom: 7px solid #f8b0da;
}

.sectionText {
    text-align: center;
    font-size: 1.2em;
}

.sectionText>a {
    text-decoration: underline;
}

@media(min-width: 600px) {
    .whiteOutlineText {
        -webkit-text-stroke-width: 4px;
    }
}

@media(min-width: 1000px) {
    .sectionContainer {
        padding: 5em 0.5em;
    }

    .sectionText {
        font-size: 1.3em;
    }
}

@media(min-width: 1500px) {
    .sectionText {
        font-size: 1.4em;
    }
}
