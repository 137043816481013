.mobileContainer {
    display: flex;
    position: fixed;
    z-index: 4;
    left: 0;
    bottom: 0;
    right: 0;
}

.mobileContainer .popupMessage {
    position: relative;
    background-color: white;
    color: #4A4A4A;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em 2em;
    box-shadow: 0 0 15px #333;
    z-index: 0;
    text-align: center;
}

.mobileContainer .title {
    font-weight: 800;
    font-size: 1.2em;
}

.mobileContainer .separator {
    width: 100%;
    margin: 0.5em 0 1.2em;
}

.mobileContainer .lowerSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.mobileContainer .diamondIcon {
    height: 45px;
    margin-bottom: 10px;
}

.mobileContainer .image {
    height: 80px;
    border-radius: 100%;
}

.mobileContainer .textContainer {
    margin-left: 1.5em;
}

.mobileContainer .description {
    text-align: left;
    font-size: 0.9em;
}

.mobileContainer .ctaContainer {
    display: flex;
    align-items: center;
}

.mobileContainer .goPremium {
    width: 120px;
    font-size: 0.9em;
}

.maybeLater {
    color: #9b9b9b;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9em;
    margin-left: 0.9em;
    text-align: left;
}

.desktopContainer {
    display: none;
}

@media(min-width: 330px) {
    .mobileContainer .goPremium {
        width: 130px;
    }

    .mobileContainer .maybeLater {
        margin-left: 1.1em;
    }
}

@media(min-width: 350px) {
    .mobileContainer .image {
        height: 90px;
    }

    .mobileContainer .description {
        font-size: 1em;
    }

    .mobileContainer .goPremium {
        width: 140px;
        font-size: 1em;
    }

    .mobileContainer .maybeLater {
        margin-left: 1.3em;
    }
}

@media(min-width: 380px) {
    .mobileContainer .goPremium {
        width: 160px;
        font-size: 1.1em;
    }

    .mobileContainer .maybeLater {
        margin-left: 1.8em;
        font-size: 1em;
    }
}

@media(min-width: 500px) {
    .mobileContainer .diamondIcon {
        height: 55px;
    }

    .mobileContainer .title {
        font-size: 1.3em;
    }

    .mobileContainer .textContainer {
        margin-left: 2em;
    }
}

@media(min-width: 600px) {
    .mobileContainer {
        display: none;
    }

    .desktopContainer {
        display: flex;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 4;
    }

    .desktopContainer .popupMessage {
        position: relative;
        background-color: white;
        color: #4A4A4A;
        width: 535px;
        height: 185px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2.5em 1.8em;
        border-radius: 7px;
        box-shadow: 0 0 15px #333;
        z-index: 0;
        text-align: center;
    }

    .desktopContainer .diamondIcon {
        height: 60px;
        margin: 1em 1em 1.5em;
    }

    .desktopContainer .image {
        height: 110px;
        border-radius: 100%;
        margin: 0 0.8em 0 0.1em;
    }

    .desktopContainer .textContainer {
        display: flex;
        flex-direction: column;
        padding: 1em;
    }

    .desktopContainer .title {
        font-size: 0.7em;
        line-height: 1.1em;
        text-align: left;
    }

    .desktopContainer .description {
        margin-top: -0.5em;
        font-size: 1.1em;
        text-align: left;
    }

    .desktopContainer .ctaContainer {
        display: flex;
        align-items: center;
        padding: 0;
        margin-top: 0.2em;
    }

    .desktopContainer .goPremium {
        width: 200px;
        font-size: 1.2em;
    }

    .desktopContainer .maybeLater {
        margin-left: 1.6em;
        font-size: 1.1em;
    }
}

@media(min-width: 1000px) {
    .desktopContainer .title {
        font-size: 0.5em;
        line-height: 1.1em;
    }
}
